#login-success, #register-success, #logout-success {
  width: 100%;
  height: 100%;
  display: grid;
  align-items: center;
  justify-items: center;
  text-align: center;

  h2 {
    max-width: 600px;
    margin-bottom: 10px;  
  }

  p {
    margin: 0;
  }

  a {
    @include link-button;
    max-width: 180px;
    margin: 30px auto;
  }
}

#account {
  button {
    @include link-button;
    background: none;
  }
}