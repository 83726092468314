* {
  box-sizing: border-box;
  text-rendering: optimizeLegibility;
}

body {
  margin: 0;
  background: $background;
  color: $text;
}

/*** TEXT ELEMENTS ***/
.logo {
  @include logo-lg;

  @media (max-width: $breakpoint-desktop) {
    @include logo-md;
  }

  @media (max-width: $breakpoint-tablet) {
    @include logo-sm;
  }
}

.logo-md {
  @include logo-md;

  @media (max-width: $breakpoint-tablet) {
    @include logo-sm;
  }
}

.logo-sm {
  @include logo-sm;
}

h1 {
  @include h1;
}

h2 {
  @include h2;
}

h3 {
  @include h3;
}

//implementation #1: just make all headers lowercase
// h1, h2, h3 {
//   text-transform: lowercase;
// }

p, ul, ol {
  @include p;
}

// a {
//   font-family: inherit;
// }

p {
  max-width: 90%;
}

strong {
  font-weight: bold;
}

u {
  text-decoration: underline;
}

a {
  color: inherit;
}

img {
  display: block;
  width: 100%;
  margin: 1rem 0;
}

.video-container {
  position: relative;
  overflow: hidden;
  width: 100%;
  padding-top: 56.25%; /* 16:9 Aspect Ratio (divide 9 by 16 = 0.5625) */
}

iframe {
  $width: 100%;
  width: 100%;
  height: 56.25vw;
  border: none;
  margin: 1rem 0;

  @media (min-width: $breakpoint-tablet) {
    height: 281.25px;
  }

  @media (min-width: $breakpoint-desktop) {
    height: 393.75px;
  }
}

#action {
  margin-top: 40px;
  
  a, button {
    @include link-button;
    background: none;
    margin-bottom: 20px;
    position: relative;
    z-index: 1;
  }

  @media (min-width: $breakpoint-desktop-wide) {
    display: flex;

    a, button {
      margin-bottom: 0px;
      margin-right: 20px;
    }
  }
}

.form-field {
  @include form-field;
}

#error p {
  color: #ff0000;
}