@mixin form-field {
  label {
    @include h3;
    display: block;
    margin-bottom: 1rem;
  }

  input {
    @include p;
    display: block;
    background: #111;
    width: 100%;
    max-width: 550px;
    margin-bottom: 2rem;
    padding: 7px 15px;
    border: 1px solid #eaeaea25;
    box-shadow: inset -0.5px -0.5px 4px #eaeaea25;
  }

  button {
    @include link-button;
    background: none;
    margin-top: 60px;
  }
}