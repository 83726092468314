$menu-icon: url("../../assets/icons/menu-button.svg");
$close-icon: url("../../assets/icons/menu-close-button.svg");

header {
  position: absolute;
}

#header-content {
  position: relative;
}

#menu-button {
  background: none;
  border: none;
  width: 24px;
  height: 24px;
  padding: 0;
  background-image: $menu-icon;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 24px;
  z-index: 3;
  cursor: pointer;
  position: absolute;
  top: 30px;
  left: 30px;

  &.open {
    background-image: $close-icon;
  }
}

#header-menu {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 300px;
  height: 100vh;
  padding: 90px 30px;
  background: $background;
  position: absolute;
  top: 0;
  transform: translateX(-300px);
  transition: transform 0.3s;
  z-index: 2;
  border-right: 1px solid #fff3;

  #line-break {
    width: 100%;
    background: #fff3;
    height: 1px;
    margin-bottom: 30px;
  }

  #nav-tab {
    margin-bottom: 20px;
  }

  #nav-tab a {
    @include h3;
    display: block;
    text-decoration: none;
  }

  #account-tab a {
    @include link-button;
    margin-bottom: 20px;
  }
}

#header-menu.open {
  transform: translateX(0px);
}

@media (min-width: $breakpoint-tablet) {
  header {
    display: block;
  }
}
