@mixin logo-lg ($color: $text) {
  font-family: "Delphine";
  font-size: 3rem;
  line-height: 1.5;
  letter-spacing: 0.03em;
  color: $color;
  margin: 0;
}

@mixin logo-md ($color: $text) {
  font-family: "Delphine";
  font-size: 2rem;
  line-height: 1.5;
  letter-spacing: 0.03em;
  color: $color;
  margin: 0;
}

@mixin logo-sm ($color: $text) {
  font-family: "Delphine";
  font-size: 1.25rem;
  line-height: 1.5;
  letter-spacing: 0.03em;
  color: $color;
  margin: 0;
}

@mixin h1 ($color: $text) {
  font-family: "VG5000";
  font-size: 3rem;
  line-height: 2.4rem;
  letter-spacing: 0.03em;
  color: $color;
}

@mixin h2 ($color: $text) {
  font-family: "VG5000";
  font-size: 2rem;
  line-height: 2.4rem;
  letter-spacing: 0.03em;
  color: $color;
}

@mixin h3 ($color: $text) {
  font-family: "VG5000";
  font-size: 1.5rem;
  line-height: 2.4rem;
  color: $color;
}

@mixin p ($color: $text) {
  font-family: "Authentic Sans";
  font-size: 1.125rem;
  // line-height: 2.4rem;
  font-weight: lighter;
  letter-spacing: 0.01em;
  color: $color;
}

@mixin p-small ($color: $text) {
  font-family: "Authentic Sans";
  font-size: 1rem;
  line-height: 1.5;
  color: $color;
}

@mixin button-text ($color: $text) {
  font-family: "Format 1452";
  font-size: 1.125rem;
  line-height: 2.4rem;
  letter-spacing: 0.03em;
  color: $color;
}

@mixin back-button ($color: $text) {
  font-family: "Delphine";
  font-size: 1.125rem;
  
  letter-spacing: 0.03em;
  color: $color;
}