/*** MOBILE ***/
#landing {
  width: 100%;
  height: 100vh;
  padding: 100px 30px;
  position: relative;

  &.blur {
    filter: blur(3px);
    pointer-events: none;
  }

  .logo {
    margin-bottom: 40px;
  }
}

#title {
  @include logo-sm;
  margin-bottom: 40px;
}

#announcements {
  max-width: 400px;
  @include p;
  margin: 2rem 0 1rem;
}

nav {
  a {
    @include h3;
    display: block;
    text-decoration: none;
  }
}

#circle-menu {
  display: none;
  width: 90%;
  max-width: 850px;
  overflow: visible;
  position: fixed;
  top: 50%;
  transform: translateY(-50%);

  path {
    stroke-width: 0.5;
    stroke: $text;
    stroke-opacity: 40%;
  }



  textpath {
    fill: $text;
  }

  textPath a {
    @include h3;
    fill: $text;
    letter-spacing: 0.1em;
    transition: font-size 0.2s;
    text-align: center;

    &:hover {
      font-size: 1.75rem;
    //   // font-style: italic;
    //   transform:skewX(5px);
    }
  }
}

// #circle-menu {
//   display: none;
// }

@media (min-width: $breakpoint-tablet) {
  #title {
    @include logo-md;
  }

  #landing {
    padding-left: 50px;
  }

  #landing-content {
    padding-top: 30vh;
  }

  nav {
    display: none;
  }

  #circle-menu {
    display: block;
    right: 3%;
  }
}

@media (min-width: $breakpoint-desktop) {
  #landing-content {
    padding-left: 4%;
  }

  #title {
    @include logo-lg;
  }

  #circle-menu {
    display: block;
    right: 8%;
  }
}

@media (min-width: $breakpoint-desktop-wide) {
  #landing-content {
    padding-left: 8%;
  }
}
