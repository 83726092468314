/*** LOGO ***/
@font-face {
  font-family: "Delphine";
  src: url("../../assets/fonts/delphine.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

/*** HEADERS ***/
@font-face {
  font-family: "VG5000";
  src: url("../../assets/fonts/VG5000-Regular_web.woff2") format("woff2"),
    url("../../assets/fonts/VG5000-Regular_web.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

/*** BODY, INPUTS, LABELS ***/
@font-face {
  font-family: "Authentic Sans";
  src: url("../../assets/fonts/AUTHENTICSans-60.woff2") format("woff2"),
    url("../../assets/fonts/AUTHENTICSans-60.woff") format("woff");
  font-weight: lighter;
  font-style: normal;
}

@font-face {
  font-family: "Authentic Sans";
  src: url("../../assets/fonts/AUTHENTICSans-90.woff2") format("woff2"),
    url("../../assets/fonts/AUTHENTICSans-90.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Authentic Sans";
  src: url("../../assets/fonts/AUTHENTICSans-130.woff2") format("woff2"),
    url("../../assets/fonts/AUTHENTICSans-130.woff") format("woff");
  font-weight: bold;
  font-style: normal;
}

/*** BUTTONS ***/
@font-face {
  font-family: "Format 1452";
  src: url("../../assets/fonts/Format_1452.woff2") format("woff2"),
    url("../../assets/fonts/Format_1452.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

//retired fonts rip :(

// @font-face {
//   font-family: "Lack";
//   src: url("../../assets/fonts/Lack-Regular.woff2") format("woff2"),
//     url("../../assets/fonts/Lack-Regular.woff2") format("woff");
//   font-weight: normal;
//   font-style: normal;
// }

// @font-face {
//   font-family: "Lack";
//   src: url("../../assets/fonts/lack-italic-webfont.woff2") format("woff2"),
//     url("../../assets/fonts/lack-italic-webfont.woff") format("woff");
//   font-weight: normal;
//   font-style: italic;
// }

// @font-face {
//   font-family: "Terminal Grotesque";
//   src: url("../../assets/fonts/terminal-grotesque.woff") format("woff");
//   font-weight: normal;
//   font-style: normal;
// }

// @font-face {
//   font-family: "Terminal Grotesque Open";
//   src: url("../../assets/fonts/terminal-grotesque_open.woff") format("woff");
//   font-weight: normal;
//   font-style: normal;
// }
