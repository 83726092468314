#page, #subpage, #post {
  margin-bottom: 75px;
}

#description-editor > button {
  margin-top: 2rem;
  background: none;
  @include link-button;
}

#page-description {
  margin-bottom: 40px;
  padding-bottom: 20px;
  border-bottom: 1px solid #eaeaea44;
}


#admin-error, #login-error {
  min-height: 600px;
  height: 100%;
  display: grid;
  align-items: center;
  justify-items: center;
}