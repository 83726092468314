#window-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background: rgba(11, 11, 11, 0.25);
  display: grid;
  align-items: center;
  justify-items: center;
  text-decoration: none;
}

#window {
  width: 100%;
  height: 100vh;
  overflow: scroll;
  padding: 30px 30px 0;
  background: $background;
  position: relative;

  .logo {
    margin-bottom: 2rem;
  }

  > div {
    margin-top: 60px;
  }
}

#back-button {
  @include logo-sm;
  background: none;
  border: none;
  cursor: pointer;
  text-decoration: none;
  position: absolute;
  right: 20px;
  top: 23px;
  z-index: 2;

  .button-icon {
    background-image: url("../../assets/icons/menu-close-button.svg");
  }
}

@media (min-width: $breakpoint-tablet) {
  #window {
    width: 90%;
    max-width: 600px;
    height: 80vh;
    box-shadow: 1px 1px 2px 3px $text;
    padding: 50px 50px 0;

    &.editor-window {
      max-width: 800px;
    }

    > div {
      margin-top: 0;
    }
  }

  #back-button {
    top: 30px;
    right: 30px;
  }
}

@media (min-width: $breakpoint-desktop) {
  #window {
    max-width: 800px;

    &.editor-window {
      max-width: 1000px;
    }
  }
}

@media (min-width: $breakpoint-desktop-wide) {
  #window.editor-window {
    max-width: 1400px;
  }
}
