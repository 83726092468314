@mixin link-button {
  @include button-text;
  display: block;
  width: 100%;
  max-width: 180px;
  height: 44px;
  box-shadow: 2px 2px 2px #000, 3px 3px 4px 1px #eaeaea;
  border: 1px solid #eaeaea20;
  text-decoration: none;
  text-align: center;
  cursor: pointer;
  transition: box-shadow 0.5s;
  padding: 3px 20px;

  &:hover {
    animation: glow 1.5s ease-in-out infinite;
  }
}

@mixin toolbar-button {
  display: block;
  @include button-text;
  font-size: 1rem;
  width: 32px;
  height: 32px;
  background-color: $background;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  border: 1px solid #eaeaea50;
  text-decoration: none;
  text-align: center;
  cursor: pointer;
  transition: box-shadow 0.5s;
  padding: 0;
}

@keyframes glow {
  0% {
    box-shadow: 2px 2px 2px #000, 3px 3px 4px 1px #eaeaea;
  }

  50% {
    box-shadow: -2px -2px 2px #000, -3px -3px 4px 1px #eaeaea;
  }

  100% {
    box-shadow: 2px 2px 2px #000, 3px 3px 4px 1px #eaeaea;
  }
}
