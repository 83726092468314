#post-editor {
  padding-bottom: 50px;
}

#preview-area {
  display: none;
}

#markdown-editor {
  textarea {
    width: 100%;
    min-height: 250px;
    resize: none;
    background: #111;
    border: 1px solid #eaeaea29;
    color: $text;
    padding: 1rem;
    @include p;
  }
}

#toolbar {
  width: 100%;
  display: flex;
  background: #111;
  border: 1px solid #eaeaea29;
  padding: 8px;
}

#toolbar button {
  @include toolbar-button;
  margin-right: 8px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100%;
  position: relative;

  &.toolbar-bold {
    background-image: url('../../assets/icons/toolbar-bold.svg')
  }

  &.toolbar-italic {
    background-image: url('../../assets/icons/toolbar-italic.svg')
  }

  &.toolbar-underline {
    background-image: url('../../assets/icons/toolbar-underline.svg')
  }

  &.toolbar-strikethrough {
    background-image: url('../../assets/icons/toolbar-strikethrough.svg')
  }

  &.toolbar-h1 {
    background-image: url('../../assets/icons/toolbar-h1.svg')
  }

  &.toolbar-h2 {
    background-image: url('../../assets/icons/toolbar-h2.svg')
  }

  &.toolbar-h3 {
    background-image: url('../../assets/icons/toolbar-h3.svg')
  }

  &.toolbar-ul {
    background-image: url('../../assets/icons/toolbar-ul.svg')
  }

  &.toolbar-ol {
    background-image: url('../../assets/icons/toolbar-ol.svg')
  }

  &.toolbar-link {
    background-image: url('../../assets/icons/toolbar-link.svg')
  }

  &.toolbar-image {
    background-image: url('../../assets/icons/toolbar-image.svg')
  }

  &.toolbar-youtube {
    background-image: url('../../assets/icons/toolbar-youtube.svg')
  }

  &.toolbar-vimeo {
    background-image: url('../../assets/icons/toolbar-vimeo.svg')
  }

  span {
    @include p-small;
    display: block;
    background: $background;
    padding: 5px;
    font-size: 0.75rem;
    line-height: 1.2;
    width: 100px;
    display: none;
    position: absolute;
    top: -50px;
    left: -40px;
    z-index: 5;

  }

  &:hover span {
    display: block;
  }
}

.submit-button {
  margin-top: 2rem;
  background: none;
  @include link-button;
}

@media (min-width: $breakpoint-desktop) {
  #preview-area {
    display: block;
  }
}

@media (min-width: $breakpoint-desktop) {
  #editor-grid {
    display: grid;
    grid-template-columns: 0.9fr 1.1fr;
    grid-gap: 0 50px;
  }
}

