.post-link {
  display: block;
  width: calc(100% - 2px);
  height: 100px;
  padding: 10px 20px;
  border: 1px solid #eaeaea20;
  text-decoration: none;
  margin: 0 auto 40px;
  box-shadow: 2px 2px 2px #111, 3px 3px 4px 1px #eaeaea;

  h3 {
    margin: 0 0 10px;
  }

  p {
    margin: 5px 0;
  }
}

.subpage-link {
  display: block;
  text-decoration: none;
  margin-bottom: 20px;

  h3 {
    margin: 0;
  }

  p {
    @include p-small;
    margin: 0;
    color: #eaeaea88;
    font-style: italic;
  }
}